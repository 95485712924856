<template>
  <div class="pa-5">
    <v-card flat>
      <v-row class="mt-4 ml-4" id="input-custom">
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
          <p class="ma-1 subtitle-2 font-weight-regular headline-color">
            Nama Pegawai
          </p>
          <p class="ma-1 subtitle-2 font-weight-bold headline-color">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="personal.nama"
            ></v-text-field>
          </p>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
          <p class="ma-1 subtitle-2 font-weight-regular headline-color">
            NIP
          </p>
          <v-card-actions class="pa-0">
            <v-text-field outlined dense hide-details v-model="personal.NIP">
            </v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-3"
                  @click="checkNIP"
                >
                  <v-icon>search</v-icon>
                </v-btn>
              </template>
              <span>Check NIP</span>
            </v-tooltip>
          </v-card-actions>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
          <v-card-actions class="mt-3" v-if="!isUpdate">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="$router.back()"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              @click="handleSubmit"
              >Simpan</v-btn
            >
          </v-card-actions>
          <v-card-actions class="mt-3" v-else>
            <v-spacer />
            <v-btn
              class="px-12 subtitle-2 text-capitalize"
              small
              color="success"
              :loading="approveLoading"
              @click="() => handleApprove('APPROVE')"
              >SETUJUI</v-btn
            >
            <v-btn
              class="px-12 subtitle-2 text-capitalize"
              small
              color="error"
              :loading="rejectLoading"
              @click="() => handleApprove('REJECT')"
              >TOLAK</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs show-arrows height="50" v-model="tab" id="tab-data">
            <v-tab
              v-for="(item, i) in tabs"
              v-bind:key="i"
              class="mr-2 px-5 text-upppercase"
              >{{ item.text }}</v-tab
            >
          </v-tabs>
          <v-tabs-items>
            <transition name="slide-fade" mode="out-in">
              <keep-alive>
                <component :is="tabs[tab].component" />
              </keep-alive>
            </transition>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import PegawaiService from "@/services/resources/pegawai.service";
import PegawaiSubmissionService from "@/services/resources/pegawai.submission.service";
import { mapEmployeeRootField } from "@/store/helpers";
import { mapGetters } from "vuex";
import { ACTION_ACCESS } from "@/common/constant";
import {
  initialPersonalState,
  initialEmployeeState,
  initialUnitKerjaState
} from "@/store/state/employee.store.js";

// Tab
const DataPribadi = () => import("./Tab/DataPribadi");
const DataKepegawaian = () => import("./Tab/DataKepegawaian");

export default {
  components: {
    DataPribadi,
    DataKepegawaian
  },
  data() {
    return {
      id: this.$route.params ? this.$route.params.submissionId : null,
      loading: false,
      formLoading: false,
      checkLoading: false,
      rejectLoading: false,
      approveLoading: false,
      tab: 0,
      tabs: [
        {
          id: "data-pribadi",
          text: `Data Pribadi`,
          icon: "",
          component: "DataPribadi"
        },
        {
          id: "data-kepegawaian",
          text: `Data Kepegawaian`,
          icon: "",
          component: "DataKepegawaian"
        }
      ]
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal",
      employee: "employee",
      keluarga: "keluarga",
      unit_kerja: "unit_kerja",
      riwayat: "riwayat"
    }),
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isEditPegawai() {
      return ACTION_ACCESS.PEGAWAI_PRESENSI.EDIT_PEGAWAI.find(
        d => d == this.currentUser.role
      );
    },
    isAdminSatker() {
      return this.currentUser.role === "ADMIN_SATKER";
    },
    isUpdate() {
      return Boolean(this.id);
    }
  },
  methods: {
    addNumber(number) {
      number = String(number);
      if (number.length == 1) {
        return `0${number}`;
      }
      return `${number}`;
    },
    handleSubmit() {
      let personal = this.$_.cloneDeep(this.personal);
      personal.pendidikan.jenjang = personal.pendidikan.jenjang?.kode_pend;
      personal.pendidikan.bidang_studi =
        personal.pendidikan.bidang_studi?.kode_studi;
      personal.rt_rw = personal.rt + "/" + personal.rw;
      personal.kelurahan = personal.kelurahan?.kode_wilayah;
      personal.kecamatan = personal.kecamatan?.kode_wilayah;
      personal.kabupaten = personal.kabupaten?.kode_wilayah;
      personal.provinsi = personal.provinsi?.kode_wilayah;
      personal.kelamin = personal.kelamin == "Pria" ? 1 : 2;
      let employee = this.$_.cloneDeep(this.employee);
      employee.jabatan_eselon.jabatan =
        employee.jabatan_eselon.jabatan?.jabatan_id;
      employee.jabatan_eselon.eselon =
        employee.jabatan_eselon.eselon?.eselon_id;
      employee.golongan_pangkat.gol = employee.golongan_pangkat.gol?.gol;
      employee.golongan_pangkat.pangkat =
        employee.golongan_pangkat.pangkat?.pangkat_id;
      let unit_kerja = this.$_.cloneDeep(this.unit_kerja);
      let payload = {
        action: 1,
        personal,
        employee: {
          ...employee,
          unit_kerja: {
            unit_utama_id: unit_kerja.unit_utama?.unit_utama_id,
            unit_kerja_2: unit_kerja.unit_kerja_2?.unit_kerja_2_id,
            unit_kerja_3: unit_kerja.unit_kerja_3?.unit_kerja_3_id,
            unit_kerja_4: unit_kerja.unit_kerja_4
              ? unit_kerja.unit_kerja_4.unit_kerja_4_id
              : null
          }
        }
      };
      this.$confirm({
        title: "Konfirmasi",
        message: `Apakah Anda yakin akan menambah pegawai ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            this.savePegawai(payload);
          }
        }
      });
    },
    handleApprove(type = "APPROVE") {
      let action,
        title = null;
      if (type == "APPROVE") {
        title = "Setujui";
        action = 100001;
      } else if (type == "REJECT") {
        title = "Menolak";
        action = 100002;
      } else if (type == "CANCEL") {
        title = "Membatalkan";
        action = 100003;
      }
      this.$confirm({
        title: `${title} Perubahan`,
        message: `Apakah anda yakin akan ${title} perubahan ini?`,
        button: {
          no: "Batal",
          yes: `${title}`
        },
        isRemark: type == "REJECT",
        callback: (confirm, remark) => {
          if (confirm) {
            if (this.isAdminSatker) {
              let payload = {
                id: this.id,
                validation: action,
                remark
              };
              this.validationHistory(payload, type.toLowerCase());
            } else {
              let payload = {
                id: this.id,
                stt: action,
                remark
              };
              this.approveHistory(payload, type.toLowerCase());
            }
          }
        }
      });
    },
    // Service
    async detailPegawai() {
      try {
        this.$store.commit("employee/resetEmployeeState");
        this.loading = true;
        await PegawaiSubmissionService.getDetail({ id: this.id })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              const dataTemp = {
                personal: {
                  ...initialPersonalState(),
                  NIP: data.NIP,
                  nama: data.nama,
                  tempat_lahir: data.tempat_lahir,
                  tanggal_lahir: data.tgl_lahir,
                  kelamin: data.kelamin,
                  status_diri: data.status_diri,
                  agama: data.agama,
                  alamat: data.alamat,
                  provinsi: {
                    kode_wilayah: data.prov_id
                  },
                  kabupaten: {
                    kode_wilayah: data.kab_kota_id
                  },
                  kecamatan: {
                    kode_wilayah: data.kec_id
                  },
                  kelurahan: {
                    kode_wilayah: data.kel_id
                  },
                  rt: data.rt,
                  rw: data.rw,
                  kode_pos: data.kode_pos,
                  pendidikan: {
                    jenjang: data.kode_pend,
                    level: data.pend_level_id,
                    bidang_studi: data.kode_studi,
                    nama_sekolah: data.nama_sekolah,
                    thn_lulus: data.thn_lulus
                  },
                  lainnya: {
                    no_KTP: data.no_KTP,
                    NPWP: data.NPWP,
                    no_taspen: data.no_taspen,
                    hp_number: data.hp_number,
                    email_address: data.email_address
                  }
                },
                employee: {
                  ...initialEmployeeState(),
                  status_peg: data.status_peg,
                  jenis_peg: data.jenis_peg,
                  cpns: {
                    tgl_mulai_CPNS: data.tgl_mulai_CPNS,
                    no_sk_CPNS: data.no_sk_CPNS,
                    tgl_sk_CPNS: data.tgl_sk_CPNS
                  },
                  pns: {
                    tgl_mulai_PNS: data.tgl_mulai_PNS,
                    no_sk_PNS: data.no_sk_PNS,
                    tgl_sk_PNS: data.tgl_sk_PNS
                  },
                  no_karpeg: data.no_karpeg,
                  no_karis_su: data.no_karis_su,
                  PMK: data.PMK,
                  PMK_bulan: data.PMK_bulan,
                  jabatan_eselon: {
                    jabatan: data.jabatan_id,
                    eselon: data.eselon_id,
                    tmt_jabatan: data.tmt_jabatan,
                    no_sk_jabatan: data.no_sk_jabatan,
                    tgl_sk_jabatan: data.tgl_sk_jabatan
                  },
                  golongan_pangkat: {
                    gol: data.gol,
                    pangkat: data.pangkat_id,
                    tmt_gol: data.tmt_gol,
                    no_sk_gol: data.no_sk_gol,
                    tgl_sk_gol: data.tgl_sk_gol
                  },
                  status_aktif: data.status_aktif
                },
                unit_kerja: {
                  ...initialUnitKerjaState(),
                  unit_utama: {
                    unit_utama_id: data.unit_utama_id
                  },
                  unit_kerja_2: {
                    unit_kerja_2_id: data.unit_kerja_2_id
                  },
                  unit_kerja_3: {
                    unit_kerja_3_id: data.unit_kerja_3_id
                  },
                  unit_kerja_4: {
                    unit_kerja_4_id: data.unit_kerja_4_id
                  }
                }
              };

              this.personal = this.$_.merge(this.personal, dataTemp.personal);
              this.employee = this.$_.merge(this.employee, dataTemp.employee);
              this.unit_kerja = this.$_.merge(
                this.unit_kerja,
                dataTemp.unit_kerja
              );
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message: "Data pegawai gagal ditambah",
              color: "error"
            });
            this.$router.back();
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async savePegawai(data) {
      try {
        this.formLoading = true;
        await PegawaiService.create(data)
          .then(response => {
            const { status, message, error } = response.data;
            if (status) {
              this.$router.back();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              if (Object.entries(error).length > 0) {
                this.$store.commit("snackbar/setSnack", {
                  show: true,
                  message: error,
                  color: "error"
                });
              } else {
                this.$store.commit("snackbar/setSnack", {
                  show: true,
                  message: message,
                  color: "error"
                });
              }
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message: "Data pegawai gagal ditambah",
              color: "error"
            });
            this.$router.back();
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async checkNIP() {
      try {
        this.checkLoading = true;
        await PegawaiService.checkNIP({
          NIP: this.personal.NIP
        })
          .then(response => {
            const { status, message } = response.data.message;
            if (status) {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message: "Check NIP gagal",
              color: "success"
            });
            this.$router.back();
            throw new Error(error);
          })
          .finally(() => (this.checkLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async approveHistory(data, type = "approve") {
      try {
        this[type + "Loading"] = true;
        await PegawaiSubmissionService.approve(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$router.back();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this[type + "Loading"] = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async validationHistory(data, type = "approve") {
      try {
        this[type + "Loading"] = true;
        await PegawaiSubmissionService.validation(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$router.back();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this[type + "Loading"] = false;
          });
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.detailPegawai();
  }
};
</script>
<style lang="scss">
#tab-custom .v-tabs-bar {
  background-color: transparent;
}
#tab-custom .v-tab {
  background-color: #e0e0e0;
  color: #555555;
  border-radius: 15px 15px 0px 0px;
  &.v-tab--active {
    background-color: #1792e6;
    color: #ffffff;
  }
}
#tab-item-custom.v-tabs-items {
  border-top: 3px solid #1792e6;
}
#tab-data .v-tab {
  font-size: 0.675rem;
}
#input-custom .v-input {
  font-size: 0.775rem;
}
</style>
